import * as React from "react"
import Demo from "../../components/Demo"
import { UnityContext } from "react-unity-webgl"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

const unityContextFoxRunner = new UnityContext({
  loaderUrl: "/games/foxrunner/FoxRunnerBuild.loader.js",
  dataUrl: "/games/foxrunner/FoxRunnerBuild.data",
  frameworkUrl: "/games/foxrunner/FoxRunnerBuild.framework.js",
  codeUrl: "/games/foxrunner/FoxRunnerBuild.wasm",
})

function handleOnClickFullscreen() {
  unityContextFoxRunner.setFullscreen(true)
}

function FoxRunnerGame() {
  const [progression, setProgression] = useState(0)

  useEffect(function () {
    unityContextFoxRunner.on("progress", function (progression) {
      setProgression(progression)
    })
  }, [])

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>OakGames - Fox Runner</title>
        <meta
          name="description"
          content="Fox Runner - an amazing game for your company"
        />
      </Helmet>
      <Demo
        progression={progression}
        unityContextGame={unityContextFoxRunner}
        handleFullScreen={handleOnClickFullscreen}
      />
    </>
  )
}

export default FoxRunnerGame
